.launch-marketplace-cont {
  margin-top: 60px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@media screen and (max-width: 768px) {
  .launch-marketplace-cont {
    flex-wrap: wrap;
  }
}
