
  
  .dashboard-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .dashboard-header {
    background-color: #FF0085;
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  .dashboard-main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .card-title {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .file-input {
    margin-bottom: 20px;
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .split-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #FF0085;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
  }
  
  .split-button:hover {
    background-color: #FF0085;
  }
  
  @media (max-width: 768px) {
    .card-title {
      font-size: 1.2rem;
    }
  
    .split-button {
      font-size: 0.875rem;
      padding: 8px 16px;
    }
  }
  