.tool-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tool-bar img {
  height: 40px;
  margin-left: 10px;
}

.tool-bar .MuiTypography-root {
  flex: 1;
  text-align: center;
}

.tool-bar .MuiButton-root {
  margin-left: auto;
}

.app-bar{
  height: 60px;
}

