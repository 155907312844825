/* Main Container */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50px;
  }
  
  /* Logo Styling */
  .login-logo {
    width: 500px;
  }
  
  /* Login Box Styling */
  .login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 
    padding: 20px;
    border-radius: 8px;
  }
  
  /* Google Button */
  .google-button {
    color: white;
    font-weight: bold;
    text-transform: none;
  }
  
  .google-button:hover {
    background-color: #357ae8;
  }
  
  /* Alert Styling */
  .login-alert {
    margin-bottom: 20px;
    width: 100%;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .login-container {
    }
  
    .login-logo {
      margin-bottom: 15px;
      object-fit: contain
    }
  
    .login-box {
      padding: 15px;
    }
  
    .google-button {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .login-logo {
      max-height: 120px;
      object-fit: contain;
    }
  
    .google-button {
      font-size: 12px;
    }
  }
  